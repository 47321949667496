<template>
  <transition name="modal">
    <div class="overlay" v-if="visible">
        <div class="modal" :class="{'modal--active': visible}">
            <div class="modal__heading">
              <h2>{{heading}}</h2>
              <div class="close" @click.stop="close()"><i class="material-icons">close</i></div>
            </div>
            <slot></slot>
        </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    visible: Boolean,
    heading: '',
    modalClass: ''
  },
  methods: {
    close() {
        this.$emit('close-event',false)
    }
  },
  watch: {
    visible: function(val, oldval) {
      this.$store.state.modalActive = val
    }
  }
}
</script>

<style scoped lang="scss">
/* See _modal.scss */
</style>
