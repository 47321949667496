<template>

    <div class="invoice-editor grid">

        <Loader v-if="!i.id && !reloading"></Loader>

        <div class="grid__left grid__left--lg-xl no-print">
            <div class="grid-cells--fixed">
                <div class="grid-cell">
                 
                </div>
            </div>
        </div>

        <div class="grid__main invoice-editor-invoice printer-page">


            <div class="invoice invoice--not-editable">

                <!-- ********** Invoice Logo ********** -->

                <div class="invoice-section">
                    <div class="invoice-section__row">
                        <div class="invoice-logo editable no-print" v-bind:style="getLogo"></div>
                        <img class="print-logo" :src="b.logo"/>
                    </div>
                </div>
               
                <div class="invoice-section invoice-section--header">
                    
                <!-- ********** Invoice Date ********** -->

                    <div class="invoice-section__row">
                        <p class="i-label editable">{{formatDate(i.date)}}</p>
                    </div>

                <!-- ********** Invoice Title & Description ********** -->
                
                    <div class="invoice-section__row">
                        <h1 class="i-title editable">{{invoiceType}} <span class="invoice-title__invoice-number">{{i.invoicenumber}}</span> <span v-if="notify" class="notification"></span></h1>
                    </div>

                    <div class="invoice-section__row">
                        <p class="i-text">{{invoiceContent || i.description}}</p>
                    </div>
                </div>

                <hr>

                <!-- ********** Invoice From ********** -->

                <div class="invoice-section">
                    <div class="invoice-section__col editable">
                        <h5 class="i-label">From</h5>
                        <p class="i-text">
                            {{b.name}}<br v-if="b.name">
                            {{b.email}}
                        </p>
                        <p class="i-text">
                            {{b.address}}<br v-if="b.address">
                            {{b.address2}}<br v-if="b.address2">
                            {{b.suburb}}<br v-if="b.suburb">
                            {{b.city}}<br v-if="b.city">
                            {{b.postalcode}}
                        </p>
                    </div>

                <!-- ********** Invoice To ********** -->

                    <div class="invoice-section__col editable">
                        <h5 class="i-label">To</h5>
                        <p class="i-text">
                            {{c.name}}<br>
                            {{c.email}}
                        </p>
                        <p class="i-text">
                            {{c.address}}<br v-if="c.address">
                            {{c.address2}}<br v-if="c.address2">
                            {{c.suburb}}<br v-if="c.suburb">
                            {{c.city}}<br v-if="c.city">
                            {{c.postalcode}}
                        </p>
                    </div>
                </div>

                <hr>

                <!-- ********** Line Items ********** -->

                <div class="invoice-section">
                    <div class="invoice-section__row">
                        <h5 class="i-label">Description</h5>
                        <h5 class="i-label text-right">Amount</h5>
                    </div>
                </div>
                <InvoiceLineItem v-for="(invoiceLineItem,index) in items" v-model="items[index]" :key="invoiceLineItem.id" v-bind:currency="currency" 
                    :itemid="invoiceLineItem.id"></InvoiceLineItem>

                <div class="invoice-section" v-if="i.showdiscount || i.showshipping || i.showvat || showExpenses">
                    <div class="invoice-section__col invoice-section__col--right">
                        <h5 class="i-heading">Subtotal</h5>
                         <vue-autonumeric v-bind:value="i.$subtotal"
                            :options="{digitGroupSeparator: ' ', currencySymbol: currency, minimumValue: '0.00' }"
                            :placeholder="currency+'0.00'"
                            tag="p" contenteditable="false"
                            class="i-heading"></vue-autonumeric>
                    </div>
                </div>

                <hr v-if="i.showdiscount || i.showshipping || i.showvat || showExpenses">

                <!-- ********** Expenses ********** -->

                <!-- <div v-if="showExpenses" class="invoice-section">
                    <h5 class="invoice-section__col i-label">Expense</h5>
                    <h5 class="invoice-section__col i-label">Amount</h5>
                </div>

                <div v-if="showExpenses">
                    <InvoiceExpense v-for="invoiceExpense in expenses" v-model="invoiceExpense.data" :key="invoiceExpense.id" v-bind:currency="currency" v-bind:invoice-is-editable="isEditable"
                        v-on:update="updateInvoiceExpense()"
                        v-on:delete="deleteInvoiceExpense(invoiceExpense)"></InvoiceExpense>
                </div>

                <div class="invoice-section" v-if="showExpenses && isEditable">
                    <div class="invoice-section__row">
                        <button class="button button--secondary" @click="addExpense()"><i class="material-icons">add_circle</i> Add expense</button>
                    </div>
                </div>

                <div v-if="showExpenses" class="invoice-section">
                    <div class="invoice-section__col invoice-section__col--right">
                        <h5 class="i-label">Subtotal</h5>
                        <vue-autonumeric v-model="expensesSubtotal"
                            :options="{digitGroupSeparator: ' ', currencySymbol: currency, minimumValue: '0.00' }"
                            :placeholder="currency+'0.00'"
                            tag="p" contenteditable="false"
                            class="i-text"></vue-autonumeric>
                    </div>
                </div>

                <hr v-if="showExpenses"> -->

                <!-- ********** Discount ********** -->

                <div v-if="i.showdiscount" class="invoice-section">
                    <div class="invoice-section__col invoice-section__col--right editable">
                        <h5 class="i-text">Discount<span v-if="discountType == 'percent'"> (<vue-autonumeric v-bind:value="(i.$discounttotal/i.$subtotal)" :options="['percentageUS2decPos', { decimalPlaces: '2', minimumValue: '0'}]" tag="span" contenteditable="false"></vue-autonumeric>)</span></h5>
                        <vue-autonumeric v-model="i.$discounttotal"
                            :options="{digitGroupSeparator: ' ', currencySymbol: currency,}"
                            :placeholder="currency+'0.00'"
                            tag="p" contenteditable="false"
                            class="i-text"></vue-autonumeric>
                    </div>
                </div>

                <!-- ********** Shipping ********** -->

                <div v-if="i.showshipping" class="invoice-section">
                    <div class="invoice-section__col invoice-section__col--right editable">
                        <h5 class="i-text">Shipping</h5>
                        <vue-autonumeric v-bind:value="i.$shippingtotal"
                            :options="{digitGroupSeparator: ' ', currencySymbol: currency, minimumValue: '0.00' }"
                            tag="p" contenteditable="false"
                            class="i-text"></vue-autonumeric>
                    </div>                    
                </div>
              
                <!-- ********** VAT ********** -->

                <div v-if="i.showvat" class="invoice-section">
                    <div class="invoice-section__col invoice-section__col--right editable">
                        <h5 class="i-text">VAT (<vue-autonumeric v-bind:value="v.percent||0" :options="['percentageUS2decPos', { decimalPlaces: '2', minimumValue: '0', maximumValue: '100' }]" tag="span" contenteditable="false"></vue-autonumeric>)</h5> 
                        <vue-autonumeric v-bind:value="this.i.$vattotal"
                            :options="{digitGroupSeparator: ' ', currencySymbol: currency, minimumValue: '0.00' }"
                            tag="p" contenteditable="false"
                            class="i-text"></vue-autonumeric>
                    </div>                    
                </div>
                
                <!-- ********** Total before payments ********** -->

                <div v-if="showPayments" class="invoice-section">
                    <div class="invoice-section__col invoice-section__col--right invoice-subtotal">
                        <hr>
                        <h5 class="i-heading">Total</h5>
                        <vue-autonumeric v-bind:value="i.$total"
                            :options="{digitGroupSeparator: ' ', currencySymbol: currency }"
                            tag="p" contenteditable="false"
                            class="i-heading"></vue-autonumeric>
                        <hr>
                    </div>
                </div>

                <!-- ********** Payments ********** -->

                <div v-if="showPayments" class="invoice-section">
                    <div class="invoice-section__col invoice-section__col--right">
                        <h5 class="i-heading">Payments</h5>
                    </div>                    
                </div>

                <div v-for="payment in payments" :key="payment.id" class="invoice-section">
                    <div class="invoice-section__col invoice-section__col--right editable" @click="isEditable ? payIsFocused = true : true;">
                        <p class="i-text">{{formatDate(payment.date)}}</p>
                        <vue-autonumeric v-bind:value="payment.amount"
                            :options="{digitGroupSeparator: ' ', currencySymbol: currency }"
                            tag="p" contenteditable="false"
                            class="i-text"></vue-autonumeric>
                    </div>                    
                </div>

                <!-- <hr v-if="showPayments"> -->

                <!-- ********** Invoice Total ********** -->
                
                <div class="invoice-section">
                    <div class="invoice-section__col invoice-section__col--right invoice-total">
                        <hr>
                        <h5 class="i-title">Total due</h5>
                        <vue-autonumeric v-bind:value="outstanding"
                            id="total-due"
                            :options="{digitGroupSeparator: ' ', currencySymbol: currency }"
                            tag="p"
                            class="i-title"></vue-autonumeric>
                        <hr>
                    </div>
                </div>


                <!-- ********** Initial Deposit ********** -->

                <div v-if="i.showdeposit" class="invoice-section">
                    <div class="invoice-section__col editable invoice-section__col--right invoice-subtotal">
                        <h5 class="i-text">Deposit<span> (<vue-autonumeric v-bind:value="(dep.amount/i.$total)||0" :options="['percentageUS2decPos', { decimalPlaces: '2', minimumValue: '0'}]" tag="span" contenteditable="false"></vue-autonumeric>)</span></h5> 
                        <vue-autonumeric v-bind:value="dep.amount"
                            :options="{digitGroupSeparator: ' ', currencySymbol: currency }"
                            tag="p" contenteditable="false"
                            class="i-text"></vue-autonumeric>
                    </div>
                </div>
                
                <!-- ********** Footer ********** -->

                <div class="invoice-section invoice-section--footer">
                
                <!-- ********** Payment details ********** -->

                    <div class="invoice-section__col editable">
                        <h5 class="i-label">Payment Details</h5>
                        <p v-if="b.showbank">{{i.paymentnote}}</p>
                        <p v-if="b.showbank">{{b.accountname}}<br v-if="b.accountname">{{b.accountnumber}}<br v-if="b.accountnumber">{{b.branchcode}}<br v-if="b.branchcode">{{b.accounttype}}<br v-if="b.accounttype">{{b.bank}}</p>
                        <p v-if="b.showpaymentlink && b.stubpayments">Pay this invoice online using Snapscan or your card.</p>
                        <a v-if="b.showpaymentlink && b.stubpayments" class="button button--primary" :href="paymentlink">Pay online</a>
                    </div>

                <!-- ********** Due Date ********** -->

                    <div class="invoice-section__col invoice-section__col--has-rows">

                        <div class="row editable" v-if="!quote">
                            <h5 class="i-label">Due Date</h5>
                            <p class="i-text">{{formatDate(i.duedate)}}</p>
                        </div>
                        

                

                <!-- ********** Invoice Number ********** -->

                        <div class="row editable">
                            <h5 class="i-label">Invoice Number</h5>
                            <p class="i-text">{{i.invoicenumber}}</p>
                        </div>

                <!-- ********** Company Registration Number ********** -->

                        <div v-if="b.registrationnumber" class="row editable">
                            <h5 class="i-label">Company registration</h5>
                            <p class="i-text">{{b.registrationnumber}}</p>
                        </div>
                      
                <!-- ********** VAT Registration Number ********** -->

                        <div v-if="i.showvat" class="row editable">
                            <h5 class="i-label">VAT Registration</h5>
                            <p class="i-text">{{b.vatnumber}}</p>
                        </div>
                    </div>
                
                </div>
            </div>
            
        </div>

        <div class="grid__right no-print">

            <div class="grid-cells--fixed">

                <div class="grid-cell grid-cell--sm-lg"></div>

                <div class="grid-cell">
                    <h4>Actions</h4>
                    <!-- <a v-if="b.showpaymentlink && b.stubpayments" class="button invoice-editor-actions__pay" :href="paymentlink">Pay online</a> -->
                    <!-- <button class="button button--primary" @click="print()">Print {{invoiceTypeL}}</button> -->
                    <StubButton v-if="quote" @click="confirmAcceptIsFocused = true;">Accept quote</StubButton>
                    <p v-if="quote" class="subtext">This will allow you to confirm whether or not you accept this quote.</p>
                    <button class="button button--secondary" @click="print()">Print {{invoiceTypeL}}</button>
                </div>

                <modal v-bind:visible="confirmAcceptIsFocused" @close-event="confirmAcceptIsFocused = false" heading="Confirm and accept quote">
                  <div class="modal__content">
                      <p>Once you accept this quote, we'll let {{b.name}} know that you are happy with it!</p>

                      <p class="subtext">Accepting this quote will turn it into an invoice</p>
                  </div>
                  <div class="modal__buttons">
                      <StubButton @click="accept()" class="button button--tertiary" :is-loading="accepting">Accept</StubButton>
                      <button class="button--secondary" @click="confirmAcceptIsFocused = false;">Cancel</button>
                  </div>
                </modal>

                <div class="grid-cell" v-if="!quote">
                    <h4>Payments</h4>
                    <div class="list">
                        <InvoicePayment
                            v-for="(payment,index) in payments" :key="payment.id" v-bind:currency="currency" v-model="payments[index]" :locked="true"/>
                    </div>
                    <p v-if="b.showpaymentlink && b.stubpayments" class="subtext"><vue-autonumeric v-bind:value="outstanding"
                        :options="{digitGroupSeparator: ' ', currencySymbol: currency, }"
                        :placeholder="currency+'0.00'"
                        tag="span" contenteditable="false"></vue-autonumeric> outstanding</p>
                    <div class="list" >
                        <span v-if="payments.length==0" class="subtext">No payments have been made yet.</span>
                    </div>
                </div>
                
                <div class="grid-cell">
                  <StubButton class="button button--secondary" @click="reload(true)" :is-loading="buttonreload">Refresh page</StubButton>
                </div>
                <a href="#"></a>
                
                <!-- <div class="grid-cell">
                    <h4>History</h4>
                    <div class="history">
                    
                        <div class="item" v-bind:class="h.event" v-for="h in hist.slice(0,4)" v-bind:key="h.id">
                            <h5 class="text gray-800">{{h.event | formatStatus}}</h5>
                            <p class="subtext">{{h.description}}
                                <vue-autonumeric v-if="h.amount" v-bind:value="h.amount"
                                :options="{digitGroupSeparator: ' ', currencySymbol: currency, minimumValue: '0.00' }"
                                :placeholder="currency+'0.00'"
                                tag="span" contenteditable="false"
                                class="currency"></vue-autonumeric>
                            </p> 
                            <p class="microtext gray-700"><em>{{formatDate(h.timestamp)}}</em></p>
                        </div>
                        
                    </div>    

                    <div class="action">
                        <span class="material-icons">history</span> Show full history
                    </div>
                </div> -->

            </div>

        </div>

        <modal class="modal--send-successful" v-bind:visible="changing" @close-event="highlight()" heading="Invoice available">
            <div class="modal__content">
                <div class="convert-quote">
                  <img src="./../assets/img/invoices.svg" alt="">
                  <i class="material-icons">arrow_forward</i> 
                  <img src="./../assets/img/invoicing.svg" alt="">
                </div>
                <h2>Your invoice is now available</h2>
                <br/>
                <p>We've let {{b.name}} know that you've accepted this quote.</p>
                <p>You can view your invoice right here</p>
            </div>
            <div class="modal__buttons">
                <button class="button" @click="highlight()">Got it</button>
            </div>
            <br/>
        </modal>

        <div class="quinvoice-footer print">
            <p>Made with</p>
            <img src="../assets/img/stub-logo.svg" alt="Stub">
        </div>
    </div>

</template>

<script>
import Loader from '../components/Loader.vue'
import Copyright from '@/components/Copyright.vue'
import InvoiceLineItem from '@/components/InvoiceLineItem.vue'
// import InvoiceExpense from '@/components/InvoiceExpense.vue'
import Modal from '@/components/Modal.vue'
import InvoicePayment from '@/components/InvoicePayment.vue'
import VueAutonumeric from '../../node_modules/vue-autonumeric/src/components/VueAutonumeric.vue'
import VuejsDatepicker from 'vuejs-datepicker'
import StubButton from '@/components/StubButton.vue'
import axios from 'axios'

var server = "https://prod.stub.africa";
if(window.location.hostname=="localhost") {
  server = "http://localhost:3000";
}
else if(window.location.hostname=="beta.stub.africa") {
  server = "https://beta-api.stub.africa";
}
else if(window.location.hostname=="127.0.0.1") {
  server = "https://beta-api.stub.africa";
}

export default {
    name: 'public-quinvoice',
    components: {
        Loader,
        Copyright,
        InvoiceLineItem,
        StubButton,
        Modal,
        // InvoiceExpense,
        InvoicePayment,
        VueAutonumeric,
        VuejsDatepicker
    },
    beforeMount: function() {
      var id = this.$route.params.id;
      var uid = this.$route.params.uid;
      var thisy  = this;
      axios.get(server+'/api/invoice/view/'+uid+'/'+id)
      .then(function (response) {
        thisy.i = response.data.invoice || {};
        thisy.b = response.data.business || {};
        console.log(response.data.invoice)
      })
      .catch(function (error) {
        console.log(error)
      });

    },
    data: function() {
        return {
            i: {},
            b: {},
            savingclient: false,
            client: {},
            selectedClient: false,
            meta: {},
            dueDate: new Date(),
            invoiceDate: new Date(),
            invoiceNumber: null,
            invoiceIsEditable: null,
            lineItems:  [],
            paymentItems:  [],
            showNewItem: false,
            newNewItem: {},
            expenseItems:  {},
            discountAmount: null,
            discountPercent:null,
            discountType: 'percent',

            discount: {},
            
            deposit:  {},
            
            inclusiveVat: false,
            invoiceContentIsFocused: false,
            invoiceContent: null,
            loadingContent: false,

            invoiceMessage: null,

            invoiceTitle: "Invoice",
            
            paymentDetails: "",
            
            showExpenses: null,
            newVat: false,
            shippingAmount: 0,
            confirmAcceptIsFocused: false,
            accepting: false,
            rejecting: false,
            changing: false,
            buttonreload: false,
            reloading: false,
            notify: false,
        }
    },
    methods: {
      formatDate: function(rawDate) {
        var date = new Date(rawDate);
        var monthNames = [
          "Jan", "Feb", "Mar",
          "Apr", "May", "Jun", "Jul",
          "Aug", "Sep", "Oct",
          "Nov", "Dec"
        ];

        var day = date.getDate();
        var monthIndex = date.getMonth();
        var year = date.getFullYear();

        return day + ' ' + monthNames[monthIndex] + ' ' + year; 
      },
      print: function() {
        document.title = this.invoiceType+ " "+this.i.invoicenumber
        window.print()
      },
      highlight: function() {
        var thisy = this;
        this.changing = false;
        this.notify = true;
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        setTimeout(function(){ 
          thisy.notify = false;
        },6000);
      },
      reload: function(showbuttonloader) {
        var id = this.$route.params.id;
        var uid = this.$route.params.uid;
        this.reloading = true;
        if(showbuttonloader) this.buttonreload = true;
        var thisy  = this;
        axios.get(server+'/api/invoice/view/'+uid+'/'+id)
        .then(function (response) {
          setTimeout(function(){ 
            thisy.i = response.data.invoice || {};
            thisy.b = response.data.business || {};
            console.log(response.data.invoice)
            thisy.reloading = false;
            thisy.buttonreload = false;
          },600);
        })
        .catch(function (error) {
          console.log(error)
        });
      },
      accept: function() {
        var id = this.$route.params.id;
        var uid = this.$route.params.uid;
        var thisy  = this;
        this.accepting = true;
        axios.get(server+'/api/invoice/accept/'+uid+'/'+id)
        .then(function (response) {
          thisy.confirmAcceptIsFocused = false;
          thisy.accepting = false;
          thisy.reload(false);
          thisy.changing = true;
        })
        .catch(function (error) {
          console.log(error)
        });
      },
      reject: function() {
        var id = this.$route.params.id;
        var uid = this.$route.params.uid;
        var thisy  = this;
        this.rejecting = true;
        axios.get(server+'/api/invoice/reject/'+uid+'/'+id)
        .then(function (response) {
          thisy.rejecting = false;
          thisy.confirmAcceptIsFocused = false;
          thisy.reload();
        })
        .catch(function (error) {
          console.log(error)
        });
      }
    },
    computed: {
      quote: function() {
          return this.i.quote || false;
      },
      invoiceType: function() {
        return (this.quote ? 'Quote' : 'Invoice')
      },
      invoiceTypeL: function() {
        return (this.quote ? 'quote' : 'invoice')
      },
      getLogo: function() {
          return "background-color: #fff; background-image: url("+(this.b.logo || '/nologo.png')+"); background-repeat: no-repeat; background-size: contain; background-position: center;";  
      },       
      id: function() {
          return this.$route.params.id
      },
      uid: function() {
          return this.$route.params.uid
      },
      currency: function() {
          return this.b.currency || "R";
      },
      c: function() {
          return this.i.client || {};
      },
      v: function() {
          return this.i.vat || {percent: 0.15};
      },
      outstanding: function() {
          return this.i.$outstanding || 0.00;
      },
      m: function() {
          return this.i.meta || {};
      },
      analysis: function() {
          return this.i.analysis || {};
      },
      invoicename: function() {
          return (this.c.name || "Invoice") + " — " + this.i.invoicenumber;
      },
      items: function() {
          var it = this.i.$items || [];
          return it;
      },
      expenses: function() {
          var items = [];
          if(this.i) {
              var it = this.i.expenses || {};
              Object.keys(it).forEach(function(key,index) {
                  var temp = {}
                  temp.data = it[key];
                  temp.id = key
                  items.push(temp)
              });
              var thisy = this;
              Object.keys(this.expenseItems).forEach(function(key,index) {
                  var temp = {}
                  temp.data = thisy.expenseItems[key];
                  temp.id = key
                  items.push(temp)
              });
          }
          return items;
      },
      statuses: function() {
          this.backdoor;
          var statuses = [];
          var now = new Date();this.t
          var due = new Date(this.i.duedate);
          if(due.getTime() < now.getTime() && !this.i.writtenoff && this.outstanding>0) statuses.push("overdue");
          if(this.i.sent) statuses.push("sent");
          if(this.i.writtenoff) statuses.push("written-off");
          if(this.outstanding < 0 && this.i.$paymentstotal > 0) statuses.push("over-paid");
          else if(this.outstanding == 0 && this.i.$paymentstotal > 0) statuses.push("paid");
          if(this.i.$paymentstotal > 0 && this.outstanding > 0) statuses.push("partially-paid");      
          if(this.i.sent && this.i.$paymentstotal == 0) statuses.push("unpaid");
          if(statuses.length==0) statuses.push("draft");
          statuses.push(this.i.date) //trigger reactivity
          return {id: Math.random(),
                  list: statuses};
      },
      dep: function() {
          return this.i.deposit || {amount: 0, percent: 0.0};
      },
      dis: function() {
          return this.i.discount || {amount: 0, percent: 0};
      },
      shippingtotal: function () {
          return this.i.shippingtotal || 0;
      },
      paymentlink:function() {
          return server+"/pay/i/"+this.i.id;
      },
      expensetotal: function() {
          //TODO
          return 0;
      },
      hist: function() {
          var hist = [];
          var history = this.i.events;
          if(history) {
              Object.keys(history).forEach(function(key,index) {
                  var temp = {}
                  temp.data = history[key];
                  temp.id = key
                  hist.push(temp)
              });
          }
          hist = hist.concat(this.payments)
          return hist.sort(function(a, b) { 
              if(a.timestamp < b.timestamp) {
                  return 1;
              } 
              else return -1;
          });
      },
      payments: function() {
          var pay = this.i.income || [];
          return pay.sort(function(a, b) { 
              if(a.timestamp < b.timestamp) {
                  return 1;
              } 
              else return -1;
          });
      },
      showPayments: function() {
          return this.payments.length > 0;
      },
      hasEmail: function() {
          return this.c.email || false;
      }
  },
}
</script>

<style lang="scss">
@import './../assets/scss/variables';

.invoice-editor {
    padding-top: 75px;
}
@media screen and (min-width: $breakpoint-md) {
    .invoice-editor {
        padding-top: 80px;
    }
}
@media screen and (min-width: $breakpoint-lg) {
    .invoice-editor {
        padding-top: 95px;
    }
}
.invoice-editor-invoice {
    padding-top: $space-4;
}

.invoice-editor-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $space-2 0;
    // border-bottom: 1px solid $gray-300;

    .invoice-editor-close {   
        // border: 1px solid $primary;
        cursor: pointer;
        color: $gray-900;
        

        &:hover {
            color: $primary;
        }

        .material-icons {
            padding: $space-1;
            display: inline-block;
            width: 24px;
            height: 24px;
            line-height: 24px;
            vertical-align: middle;
            background: $gray-200;
            border-radius: 50%;
        }
        .back-text {
            padding: $space-1;
            line-height: 24px;
            font-weight: 600;
            vertical-align: middle;
            display: none;
        }
    }
    .invoice-editor-title {
        flex-grow: 1;
        text-align: center;
        h1 {
            margin: $space-0;
        }
    }
    .invoice-editor-actions  {
        .invoice-editor-actions__more { display: inline-block; }
        .invoice-editor-actions__preview { display: none; }
        .invoice-editor-actions__send { display: none; }
        .invoice-editor-actions__pay { display: none; }
    }
}

@media screen and (min-width: $breakpoint-md) {
    .invoice-editor-header  {
        .invoice-editor-close {
            margin: 0;
        }
    }
}

@media screen and (min-width: $breakpoint-lg) {
    .invoice-editor-header  {
        padding: $space-3 0;

        .invoice-editor-close {
            width: 300px;
            .material-icons {
                background: none;
            }
            .back-text {
                display: inline;
            }
        }
        .invoice-editor-actions  {
            width: 300px;
            text-align: right;
            .invoice-editor-actions__more { display: inline-block; }
            .invoice-editor-actions__preview { display: inline-block; }
            .invoice-editor-actions__send { display: inline-block; }
            .invoice-editor-actions__pay { display: inline-block; }
    }
    }
}

.edit-invoice {
    background: $gray-200;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: $space-2;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    
    h4, button {
        margin: 0;
    }
}
.edit-invoice +  .invoice {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
@media screen and (min-width: $breakpoint-md) {
    .edit-invoice {
        padding: $space-2 $space-3;
    }
}

.analysis-result {
    margin: $space-2 0 0;
}
.print-logo {
  display: none;
}

.quinvoice-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 30px 0 30px 0;

  p {
    font-size: $subtext-sm;
    color: $black-coral;
    margin: 0;
    font-weight: 400;
  }
}

.convert-quote {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}


@media print {

  @page {
    size: 330mm 460mm;
    margin: 20mm 35mm 20mm 35mm;
  }

  html, body {
    background: #fff;
    margin: 0;
  }

  #app.app {
    background-color: #fff;
  }
  
  .stub-footer {
    display: none;
  }

    .invoice-editor {
    padding-top: 0 !important;
  }
  
  .invoice .invoice-section {
    flex-wrap: nowrap;
  }

  .no-print {
   display: none;
  }
  
  .print-logo {
    display: inline;
    height: 150px;
    width: 150px;
  }

  .printer-page {
    break-inside: avoid;
    page-break-inside: avoid;
    margin-left: auto;
    margin-right: auto;
  }

}
</style>