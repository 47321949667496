import Vue from 'vue'
import App from './Static.vue'
import router from './static-router'

Vue.config.productionTip = false

router.afterEach((to, from) => {
  if(firebase) {
    if(firebase.analytics()) {
      console.log({event: 'navigation-'+to.name, router: 'static', timestamp: Date.now()})
      firebase.analytics().logEvent('navigation-'+to.name);
    }
  }
})

Vue.prototype.$store = {
  dispatch: function (name,event) {
    if(firebase) {
      if(firebase.analytics()) {
        console.log({event: event, router: 'static-'+name, timestamp: Date.now()})
        firebase.analytics().logEvent(event);
      }
    }
  }
}

new Vue({
  router,
  render: h => h(App)
}).$mount('#static')
