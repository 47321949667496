<template>
    <div class="invoice-section">
        <div v-if="!hidden" class="invoice-section__row line-item editable" @click="editLineItem()">
            <div>
                <p class="i-text">{{value.description}}</p>
                <p class="i-label">
                    <span>{{value.quantity}}</span>&nbsp;&times;&nbsp;
                    <vue-autonumeric v-model="value.price"
                        :options="{digitGroupSeparator: ' ', currencySymbol: currency, minimumValue: '0.00' }"
                        tag="span" contenteditable="false"></vue-autonumeric>
                </p>
            </div>
            <vue-autonumeric v-bind:value="amount"
                :options="{digitGroupSeparator: ' ', currencySymbol: currency, minimumValue: '0.00' }"
                tag="p" contenteditable="false"
                class="i-text"></vue-autonumeric>
        </div>
        <!-- Modal -->
        <modal v-bind:visible="isFocused || show" @close-event="closeModal()" heading="Item">

            <div class="modal__content" v-if="!productSelected">
                <input type="search" placeholder="Search products...">
                <div class="list">
                    <div class="list__row" @click="createProduct()">
                        <div class="cell" ><a class="action"><span class="material-icons">add_circle_outline</span> Create product</a></div>
                    </div>
                    <div class="list__row" v-for="product in productlist" :key="product.id" @click="selectProduct(product)">
                        <div class="cell">{{product.description}}</div>
                        <div class="cell cell--chevron material-icons">
                            chevron_right
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal__content" v-if="productSelected">
                <a class="action" @click="productSelected=false;"><span class="material-icons">chevron_left</span> Change product</a>

                <fieldset>
                    <div class="tabs">
                        <div :class="activeTab == 1 ? 'tab tab--active' : 'tab'" @click="activeTab = 1">Product</div>
                        <div :class="activeTab == 2 ? 'tab tab--active' : 'tab'" @click="activeTab = 2">Cost</div>
                        <div :class="activeTab == 3 ? 'tab tab--active' : 'tab'" @click="activeTab = 3">Notes</div>
                    </div>

                    <div :class="activeTab == 1 ? 'tab-content tab-content--active' : 'tab-content'">
                        <label for="description">Name</label>
                        <input id="item-description" type="text" name="description" v-model="tempval.description">
                        <div class="space"></div>
                        <label class="radio-button radio-button--inline">Quantity
                            <input type="radio" name="productunit" value="quantity" v-model="tempval.unit">
                            <span class="radio-mark"></span>
                        </label>
                        <label class="radio-button radio-button--inline">Hours
                            <input type="radio" name="productunit" value="hours" v-model="tempval.unit">
                            <span class="radio-mark"></span>
                        </label>
                        <vue-autonumeric id="item-qty" v-model="tempval.quantity"
                            :options="['numericPos']"></vue-autonumeric>                        
                        <label for="price">Price</label>
                        <vue-autonumeric id="item-price" v-model="tempval.price"
                            :options="{digitGroupSeparator: ' ', currencySymbol: currency, minimumValue: '0.00'}"
                            :placeholder="currency+'0.00'"></vue-autonumeric>
                        <label for="amount">Total</label>
                        <vue-autonumeric v-bind:value="amount"
                            :options="{digitGroupSeparator: ' ', currencySymbol: currency, minimumValue: '0.00'}"
                            :placeholder="currency+'0.00'" 
                            disabled></vue-autonumeric>
                    </div>

                    <div :class="activeTab == 2 ? 'tab-content tab-content--active' : 'tab-content'">
                        <label for="price">Cost</label>
                        <vue-autonumeric v-model="tempval.cost"
                            :options="{digitGroupSeparator: ' ', currencySymbol: currency, minimumValue: '0.00'}"
                            :placeholder="currency+'0.00'"></vue-autonumeric>
                        <p class="input-description">
                            Price:
                            <vue-autonumeric v-bind:value="tempval.price"
                                :options="{digitGroupSeparator: ' ', currencySymbol: currency, minimumValue: '0.00'}"
                                :placeholder="currency+'0.00'" 
                                tag="span"
                                contenteditable="false"></vue-autonumeric>
                        </p>
                        <label v-if="tempval.quantity != 1" for="totalcost">Total Cost</label>
                        <vue-autonumeric v-if="tempval.quantity != 1" v-bind:value="totalcost"
                            :options="{digitGroupSeparator: ' ', currencySymbol: currency, minimumValue: '0.00'}"
                            :placeholder="currency+'0.00'" 
                            disabled></vue-autonumeric>
                        <label for="totalcost">Profit</label>
                        <vue-autonumeric v-bind:value="profit"
                            :options="{digitGroupSeparator: ' ', currencySymbol: currency }"
                            :placeholder="currency+'0.00'" 
                            disabled></vue-autonumeric>
                        <p class="input-description">Markup: {{profitmargin}}</p>
                    </div>

                    <div :class="activeTab == 3 ? 'tab-content tab-content--active' : 'tab-content'">
                        <label for="notes">Notes</label>
                        <textarea name="notes" v-model="tempval.notes"></textarea>
                    </div>
                </fieldset>
            </div>
            <div class="modal__buttons modal__buttons--divider" v-if="productSelected">
                <StubButton v-if="newitem" @click="updateLineItem()" v-bind:isLoading="saving"><span v-if="productIsNew">Create and add to {{type||'invoice'}}</span><span v-else>Add to {{type||'invoice'}}</span></StubButton>
                <StubButton v-if="!newitem" @click="updateLineItem()" v-bind:isLoading="saving"><span>Update</span></StubButton>
                <button v-if="!hidden" class="button button--danger" @click="deleteLineItem()">Remove</button>
            </div>
        </modal>
        <!-- End Modal -->
    </div>
</template>

<script>
import Modal from './Modal.vue'
import StubButton from './StubButton.vue'
import VueAutonumeric from '../../node_modules/vue-autonumeric/src/components/VueAutonumeric.vue'

export default {
    name: 'InvoiceLineItem',
    components: {
        Modal,
        StubButton,
        VueAutonumeric
    },
    props: ['value','currency','invoiceIsEditable','show','newitem','itemid','type'],
    data: function() {
        return {
            isFocused: false,
            isDeleted: false,
            saving: false,
            activeTab: 1,
            productSelected: this.newitem == undefined,
            productIsNew: this.newitem,
            tempval: this.value,
        }
    },
    methods: {
        editLineItem() {
            this.isFocused = this.invoiceIsEditable ? true : false;
            this.saving = false;
        },
        updateLineItem() {
            // TODO: Implement update event
            this.$emit('update', this.tempval);
            var thisy = this;
            this.saving = true;
            if(this.productIsNew) {
                this.$store.dispatch('createProduct',this.tempval);
            }
            setTimeout(function() {
                thisy.isFocused = false;
                thisy.saving = false;
                thisy.productSelected = false;
                thisy.productIsNew = false;
                thisy.closeModal();
            },1000)
           
        },
        deleteLineItem() {
            // TODO: Implement delete event
            this.$emit('delete', this.tempval);
        },
        closeModal: function() {
            this.isFocused = false;
            this.$emit('close', this.tempval);
        },
        createProduct() {
            this.productSelected = true;
            this.productIsNew = true;
            this.tempval = {
                quantity: 1,
                unit: 'quantity',
                price: 0,
                cost: 0,
                description: ''
            }
        },
        selectProduct(product) {
            this.productSelected = true;
            this.productIsNew = false;
            this.tempval = product;
        }
    },
    computed: {
        productlist: function() {
            var products = this.$store.getters.business.products || [];
            return products;
        },
        amount: function () {
            if (this.tempval.quantity && this.tempval.price) {
                this.tempval.amount = this.tempval.quantity * this.tempval.price;
            }
            else this.tempval.amount = 0;
                this.value.amount = this.tempval.amount;
            return this.tempval.amount;
        },
        hidden: function () {
            return this.newitem != undefined;
        },
        profitmargin :function() {
            if (this.tempval.cost == 0) {
                return '100%';
            } 
            if (this.tempval.price == 0) {
                return '-100%';
            }
            return Math.round((this.tempval.price - this.tempval.cost) / this.tempval.cost * 10000)/100 + '%';
        },
        totalcost: function() {
            return this.tempval.cost * this.tempval.quantity;
        },
        profit: function() {
            return (this.tempval.price - this.tempval.cost) * this.tempval.quantity;
        }
        
    }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/variables';

</style>