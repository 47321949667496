<template>
  <div id="app" class="app app--loggedout">

    <!-- Static Header -->
    <header class="nav">
      <div class="nav-bar">
        <router-link @click.native="hideMenu()" class="nav-logo" to="/"><img class="stub-logo"  src="./assets/img/stub-logo.svg" alt="Stub"></router-link>
        <div class="nav-toggle" @click="toggleMenu()">
          <span class="nav-toggle__menu">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#1E262E" width="24px" height="24px"><path d="M0 0h24v24H0z" fill="none"/><path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/></svg>
          </span>
          <span class="nav-toggle__close">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#1E262E" width="24px" height="24px"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
          </span>
        </div>
      </div>
      <div class="nav-menu">
        <router-link @click.native="hideMenu()" class="nav-logo" to="/"><img class="stub-logo" src="./assets/img/stub-logo.svg" alt="Stub"></router-link>
        <router-link @click.native="hideMenu()" class="nav-item" to="/">Features</router-link>
        <router-link @click.native="hideMenu()" class="nav-item" to="/about">About</router-link>
        <router-link @click.native="hideMenu()" class="nav-item nav-item--stretchy" to="/pricing">Pricing</router-link>
        <a class="nav-item nav-item--nb" href="/signup">Sign&nbsp;up</a>
        <a class="button" href="/login">Log&nbsp;in</a>
      </div>
    </header>
    <!-- end Static Header -->
    
    <!-- Router View -->
    <main>
      <router-view/>
    </main>
    <!-- end Router View -->

    <!-- Footer -->
    <footer class="stub-footer">
      <Copyright />
    </footer>
    <!-- end Footer -->
  </div>
</template>

<script>
import StubButton from './components/StubButton.vue'
import Copyright from './components/Copyright.vue'

export default {
  name: 'StaticApp',
  components: {
    StubButton,
    Copyright
  },
  methods: {
    toggleMenu() {
      document.getElementById('app').classList.toggle('show-nav')
    },
    hideMenu() {
      document.getElementById('app').classList.remove('show-nav')
    }
  }
}
</script>
<style lang="scss">
@import './assets/scss/sds.scss';

body {
  background: $light;
}

.app.focus-mode {
  background: $gray-100;
  min-height: 100vh;

  header.nav,
  .nav-spacer {
    display: none;
  }
}

.app--loggedout {
  .stub-footer {
    background: $gray-100;
  }
}

.environment {
  position: absolute;
  left: $space-4;
  bottom: $space-3;
  font-size: $subtext-sm;
  pointer-events: none;
}
@media screen and (max-width: $breakpoint-md - 1px) {
  
  .environment {
    opacity: 0;
  }

  .show-nav .environment {
    opacity: 1;
  }
}

</style>
