<template>
  <div>
    <div class="page">
      <section>
        <div class="info-cards">
          <div class="info-card info-card--hero">
              <img src="../../assets/img/business.svg"/>
              <h1 class="super">Building stub</h1>
              <p>We're building a platform to help businesses succeed. Here are some of the upcoming features that we're busy working on.</p>
          </div>

          <div class="info-card">
              <img src="../../assets/img/clients.svg"/>
              <h2 class="title">Client management</h2>
              <div class="pill pill--warning">Coming soon</div>
              <p>Manage all of your clients and understand them in more detail over time. stub will start to show you what type of clients your business is attracting, and help you keep track of the profitable (and the not so profitable) ones.</p>
          </div>

          <div class="info-card">
              <img src="../../assets/img/inventory.svg"/>
              <h2 class="title">Inventory management</h2>
              <div class="pill pill--warning">Coming soon</div>
              <p>Manage your catalogue of services or your inventory of products. stub will help you understand which of them is really the most profitable for your business.</p>
          </div>

          <div class="info-card">
              <h2 class="title">Quotes</h2>
              <div class="pill pill--warning">Coming soon</div>
              <p>Create quotes and generate invoices from them (with no extra work).</p>
          </div>

          <div class="info-card">
              <h2 class="title">Import &amp; export transactions</h2>
              <div class="pill pill--warning">Coming soon</div>
              <p>Import transactions from your bank statements and export your data for use elsewhere. Your data is your data.</p>
          </div>

          <div class="info-card">
              <h2 class="title">Detailed Insights</h2>
              <div class="pill pill--warning">Coming soon</div>
              <p>We're continuously expanding the range and detail of insights that stub generates.</p>
          </div>

          <div class="info-card">
              <h2 class="title">Tax reconciliation, reporting, API platform and more...</h2>
              <div class="pill pill--warning">Coming soon</div>
              <p>Get comprehensive reports and stay on top tax.</p>
          </div>

          
        </div>
      </section>

      <section class="section--something-else">
        <div class="info-cards">
          <div class="info-card info-card--hero">
              <h2>Want to see something else here?</h2>
              <p>Send an email to hi@stub.africa or tweet us <a target="_blank" href="https://www.twitter.com/stubafrica/">@stubafrica</a>.</p>
          </div>
        </div>
      </section>
    </div>

    <StaticFooter trackingTag="building-stub" />
  </div>
</template>

<script>
import StaticFooter from "../../components/StaticFooter.vue"

export default {
  name: 'building-stub',
  components: {
    StaticFooter
  },
  data: function() {
    return {
      
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables';

.section--something-else {
    margin: $space-4 0;
}

@media screen and (min-width: $breakpoint-md) {
    .section--something-else {
        margin: $space-6 0;
    }
}
</style>
