var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section--static-footer"},[_c('div',{staticClass:"page columns"},[_c('div',{staticClass:"one-of-four"},[_c('h5',[_vm._v("stub")]),_c('ul',[_c('li',[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Features")])],1),_c('li',[_c('router-link',{attrs:{"to":"/pricing"}},[_vm._v("Pricing")])],1),_vm._m(0)])]),_c('div',{staticClass:"one-of-four"},[_c('h5',[_vm._v("Resources")]),_c('ul',[_vm._m(1),_vm._m(2),_c('li',[_c('router-link',{attrs:{"to":"/building-stub"}},[_vm._v("Building stub")])],1),_vm._m(3),_vm._m(4),_vm._m(5)])]),_c('div',{staticClass:"one-of-four"},[_c('h5',[_vm._v("Company")]),_c('ul',[_c('li',[_c('router-link',{attrs:{"to":"/about"}},[_vm._v("About Us")])],1),_c('li',[_c('router-link',{attrs:{"to":"/about"}},[_vm._v("Mission")])],1),_c('li',[_c('router-link',{attrs:{"to":"/careers"}},[_vm._v("Careers")])],1)])]),_c('div',{staticClass:"one-of-four"},[_c('h5',[_vm._v("Follow us")]),_c('ul',[_c('li',[_c('a',{attrs:{"target":"_blank","href":"https://www.instagram.com/stubafrica/"},on:{"click":function($event){return _vm.instagram()}}},[_c('img',{attrs:{"src":require("../assets/icons/instagram.svg"),"height":"20px","width":"20px"}}),_vm._v(" Instagram")])]),_c('li',[_c('a',{attrs:{"target":"_blank","href":"https://www.facebook.com/stubafrica/"},on:{"click":function($event){return _vm.facebook()}}},[_c('img',{attrs:{"src":require("../assets/icons/facebook.svg"),"height":"20px","width":"20px"}}),_vm._v(" Facebook")])]),_c('li',[_c('a',{attrs:{"target":"_blank","href":"https://twitter.com/stubafrica"},on:{"click":function($event){return _vm.twitter()}}},[_c('img',{attrs:{"src":require("../assets/icons/twitter.svg"),"height":"20px","width":"20px"}}),_vm._v(" Twitter")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"https://medium.com/stubafrica"}},[_vm._v("What's new?")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"target":"_blank","href":"https://support.stub.africa"}},[_vm._v("Help Centre")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"https://medium.com/stubafrica"}},[_vm._v("News")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_vm._v("Media Centre "),_c('span',{staticClass:"pill pill--new"},[_vm._v("coming soon")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_vm._v("Status "),_c('span',{staticClass:"pill pill--new"},[_vm._v("coming soon")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_vm._v("API "),_c('span',{staticClass:"pill pill--new"},[_vm._v("coming soon")])])
}]

export { render, staticRenderFns }