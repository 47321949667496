<template>
  <button @click="action()" v-bind:class="{ loading : isLoading}"> 
    <slot class="label"></slot>
    <spinner class="spinner"></spinner>
  </button>
</template>

<script>
import Spinner from './Spinner.vue'

export default {
  name: 'StubButton',
  components: {
    Spinner
  },
  props: ['isLoading'],
  data: function() {
    return {
     
    }
  },
  methods: {
    action: function() {
      if(!this.isLoading) {
        this.$emit('click');
      }
    }
  }
}
</script>

<style lang="scss" scoped> 
@import '../assets/scss/variables';
@import '../assets/scss/button';

button {
  position: relative;
}
.spinner {
  position: absolute;
  top: calc( 50% - 12px );
  left: calc( 50% - 11px );
  z-index: 2;
  opacity: 0;
}
.loading  {
  color: transparent !important;
}
.loading .spinner {
  opacity: 1;
}
</style>
