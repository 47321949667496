<template>
<div>
  <div class="page">

    <section class="section--title">
        <h1 class="super">Your Digital CFO</h1>
        <p class="subtitle">From invoicing to insights, stub takes care of your finances so you can do what do you do best: take care of your business.</p>
        <a @click="track()" href="/signup" class="button button--hero">Get started</a>
        <p class="subtext">30-day free trial • No card required</p>
    </section>

    <section class="section--video col-8 gap-3">
      <div class="placeholder" v-if="!showVideo" @click="showVideo = true">
        <img 
          src="../../assets/img/title-video-placeholder@1x.jpg" 
          srcset="
          ../../assets/img/title-video-placeholder@1x.jpg 1x, 
          ../../assets/img/title-video-placeholder@2x.jpg 2x" alt="stub promo video">
        <div class="play-button">
          <img src="../../assets/img/play.svg" alt="play">
        </div>
      </div>
      <div class="video" v-else>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/bUBMDWy0-S8?autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </section>

    <section class="section--features">
        <h2 class="section--features__title title">Everything your business needs to get ahead</h2>

        <div class="info-cards">

          <div class="info-card">
            <img src="../../assets/img/insights.svg"/>
            <h2 class="title">Daily insights</h2>
            <p class="text">Get insight into the daily running of your business. See how you're tracking throughout the month.</p>
          </div>

          <div class="info-card">
            <img src="../../assets/img/invoicing.svg"/>
            <h2 class="title">Unlimited Invoicing</h2>
            <p class="text">Send, manage and track your invoices. Add as many clients as you like and manage your products and services, all while making sure you get paid on time!</p>
          </div>

          <div class="info-card">
            <img src="../../assets/img/income.svg"/>
            <h2 class="title">Payments &amp; Income</h2>
            <p class="text">Track all of your business income. We'll help you with forecasts so that you can plan for the future and make smart business decisions.</p>
          </div>

          <div class="info-card">
            <img src="../../assets/img/expenses.svg"/>
            <h2 class="title">Expense tracking</h2>
            <p class="text">Keep track of all of your expenses. No more overspending in places you shouldn't be.</p>
          </div>

          <div class="info-card">
            <img src="../../assets/img/clients.svg"/>
            <h2 class="title">Client management</h2>
            <p class="text">Understand your customers. Know where they're spending and where they're not...</p>
          </div>

          <div class="info-card">
            <img src="../../assets/img/inventory.svg"/>
            <h2 class="title">Inventory, tax &amp; more...</h2>
            <div class="pill pill--new">Coming soon</div>
            <p class="text">Manage your inventory, generate reports, stay on top of your tax, and <router-link to="/building-stub">much more to come...</router-link></p>
          </div>

          <div class="info-card info-card--hero">
            <h2 class="title">Analytics &amp; AI</h2>
            <div class="pill pill--new">Coming soon</div>
            <p>Use advanced analytics and artificial intelligence to generate financial insights for your business. Learn about your business as it grows — your industry, your region and your specific customers.</p>
          </div>
        </div>
    </section>

    <section class="section--just-start">
      <div class="info-cards">
        <div class="info-card info-card--hero">
          <h2 class="mega">just start</h2>
          <p>Great businesses are built one step at a time. Let's get going.</p>
          <a @click="track()" href="/signup" class="button">Sign up</a>
        </div>
      </div>
    </section>
  </div>

  <StaticFooter trackingTag="home" />
</div>
</template>

<script>
import StaticFooter from "../../components/StaticFooter.vue"

export default {
  name: 'home',
  components: {
    StaticFooter
  },
  data: function() {
    return {
      showVideo: false,
    }
  },
  methods: {
    track: function() {
      this.$store.dispatch('event','cta-home-first');
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables';

.section--title {
  text-align: center;
  .subtitle {
    margin: $space-4 0;
  }
}
.section--video {
  max-width: 860px;
  margin: 0 auto;
  box-shadow: 0 0 $space-4 $gray-500;
  background: $gray-800;
  margin-top: $space-4;
  border-radius: $border-radius-active;
  overflow: hidden;
  border: 1px solid $gray-300;

  .placeholder {
    position: relative;
    cursor: pointer;

    img {
      width: 100%;
      height: auto;
    }

    .play-button {
      width: 36px;
      height: 36px;
      background: $dark;
      padding: 18px;
      border-radius: 50%;
      position: absolute;
      top: calc(50% - 36px);
      left: calc(50% - 36px);
      transition: transform 0.08s linear;
      opacity: 0.9;
    }
    &:hover {
      .play-button {
        transform: scale(1.1);
      }
    }
    &:active {
      .play-button {
        transform: scale(1);
      }
    }
  }

  .video {    
    position: relative;
    height:0;
    padding-bottom: 56.3%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    
  }
}
.section--features {
  .section--features__title {
    text-align: center;
    margin: $space-6 0 $space-4;
  }
}
.section--just-start {
    margin: $space-4 0;
  }

@media screen and (min-width: $breakpoint-md) {
  .section--title {
      .subtitle {
        width: 66.66%;
        margin-left: auto;
        margin-right: auto;
      }
  }
  .section--video {
    margin-top: $space-5
  }
  .section--just-start {
    margin: $space-6 0;
  }
}
@media screen and (min-width: $breakpoint-xl) {
  .section--title {
      .subtitle {
        width: 50%
      }
  }
  .section--video {
    margin-top: $space-6;
  }
}
</style>
