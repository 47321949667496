<template>
<div>
  <div class="page">
    <section class="section--thanks">
      <div class="info-cards">
        <div class="info-card info-card--hero">
          <img src="../../assets/img/clients.svg"/>
        <h1 class="title">Thank you for signing up for our beta</h1>
        <p class="intro">Keep your eye on your emails. We'll reach out shortly. Other queries? Send us a note on <em>hi@stub.africa</em> and we'll get back to you as soon as possible!</p>
        </div>
      </div>
    </section>
  </div>
  
  <StaticFooter trackingTag="thankyou" />
</div>
</template>

<script>
import StaticFooter from "../../components/StaticFooter.vue"

export default {
  name: 'thankyou',
  components: {
    StaticFooter
  },
  data: function() {
    return {
      
    }
  },
  methods: {
    facebook: function() {
      this.$store.dispatch('event','facebook-pricing');
    },
    twitter: function() {
      this.$store.dispatch('event','twitter-pricing');
    },
    instagram: function() {
      this.$store.dispatch('event','instagram-pricing');
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables';

.section--thanks {
  .info-cards {
    margin-top: $space-6;
    margin-bottom: $space-6 * 2;
  }
}
</style>
