var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"page"},[_c('section',[_vm._m(0),_c('div',{staticClass:"plans-list"},[_c('div',{staticClass:"plan"},[_vm._m(1),_c('h2',{staticClass:"plan__title title"},[_vm._v("Side Hustle")]),_vm._m(2),_vm._m(3),_c('div',{staticClass:"plan__cta"},[_c('p',[_c('a',{staticClass:"button button--hero",attrs:{"href":"/signup"},on:{"click":function($event){return _vm.signup()}}},[_vm._v("Sign up")])]),_c('p',{staticClass:"subtext"},[_vm._v("30-day free trial • No card required")])])]),_vm._m(4),_vm._m(5)])]),_c('section',{staticClass:"section--not-sure"},[_c('div',{staticClass:"info-cards"},[_c('div',{staticClass:"info-card info-card--hero"},[_c('img',{attrs:{"src":require("../../assets/img/inventory.svg")}}),_c('h2',[_vm._v("Still not sure?")]),_c('p',{staticClass:"subtext"},[_vm._v("stub is free for 30 days when you first "),_c('router-link',{attrs:{"to":"/signup"}},[_vm._v("sign up")]),_vm._v(". You can cancel, upgrade or downgrade your subscription whenever you like.")],1)])])])]),_c('StaticFooter',{attrs:{"trackingTag":"pricing"}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"plans-header"},[_c('h1',{staticClass:"super"},[_vm._v("Choose the right stub plan for you")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"plan__subtitle"},[_c('strong',[_vm._v("The Sneaky")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"plan__features"},[_c('li',[_vm._v("Basic insights")]),_c('li',[_vm._v("Invoicing")]),_c('li',[_vm._v("Expenses")]),_c('li',[_vm._v("Income")]),_c('li',[_vm._v("Client management")]),_c('li',[_vm._v("Product management")]),_c('li',[_vm._v("Quoting "),_c('span',{staticClass:"pill pill--new"},[_vm._v("coming soon")])]),_c('li',[_vm._v("Receipts "),_c('span',{staticClass:"pill pill--new"},[_vm._v("coming soon")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"plan__price subheading"},[_c('strong',[_vm._v("R 159.00")]),_vm._v(" p/m")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"plan"},[_c('p',{staticClass:"plan__subtitle"},[_c('strong',[_vm._v("The Growing")])]),_c('h2',{staticClass:"plan__title title"},[_vm._v("Startup")]),_c('ul',{staticClass:"plan__features"},[_c('li',{staticClass:"gray-700"},[_vm._v("All "),_c('em',[_vm._v("Side Hustle")]),_vm._v(" features")]),_c('li',[_vm._v("In-depth insights")]),_c('li',[_vm._v("Client Relationship Management")]),_c('li',[_vm._v("Import bank statements")]),_c('li',[_vm._v("Invoice payments")]),_c('li',{staticClass:"invisible"}),_c('li',{staticClass:"invisible"}),_c('li',{staticClass:"invisible"})]),_c('p',{staticClass:"plan__price subheading gray-700"},[_c('strong',[_vm._v("R 279.00")]),_vm._v(" p/m")]),_c('div',{staticClass:"plan__cta"},[_c('div',{staticClass:"pill"},[_vm._v("Coming soon")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"plan"},[_c('p',{staticClass:"plan__subtitle"},[_c('strong',[_vm._v("The Established")])]),_c('h2',{staticClass:"plan__title title"},[_vm._v("Entrepreneur")]),_c('ul',{staticClass:"plan__features"},[_c('li',{staticClass:"gray-700"},[_vm._v("All "),_c('em',[_vm._v("Startup")]),_vm._v(" features")]),_c('li',[_vm._v("Inventory management")]),_c('li',[_vm._v("SARS tax reconciliation")]),_c('li',{staticClass:"invisible"}),_c('li',{staticClass:"invisible"}),_c('li',{staticClass:"invisible"}),_c('li',{staticClass:"invisible"}),_c('li',{staticClass:"invisible"}),_c('li',{staticClass:"invisible"})]),_c('p',{staticClass:"plan__price subheading gray-700"},[_c('strong',[_vm._v("R 479.00")]),_vm._v(" p/m")]),_c('div',{staticClass:"plan__cta"},[_c('div',{staticClass:"pill"},[_vm._v("Coming soon")])])])
}]

export { render, staticRenderFns }