<template>
  <div>
    <div class="page">
      <section>
        <div class="plans-header">
          <h1 class="super">Choose the right stub plan for you</h1>
        </div>

        <div class="plans-list">
            <div class="plan"> 
              <p class="plan__subtitle"><strong>The Sneaky</strong></p>
              <h2 class="plan__title title">Side Hustle</h2>
              <ul class="plan__features"> 
                  <li>Basic insights</li>
                  <li>Invoicing</li>
                  <li>Expenses</li>
                  <li>Income</li>
                  <li>Client management</li>
                  <li>Product management</li>
                  <li>Quoting <span class="pill pill--new">coming soon</span></li>
                  <li>Receipts <span class="pill pill--new">coming soon</span></li>
              </ul>
              <p class="plan__price subheading"><strong>R 159.00</strong> p/m</p>
              <div class="plan__cta">
                  <p><a @click="signup()" href="/signup" class="button button--hero">Sign up</a></p>
                  <p class="subtext">30-day free trial • No card required</p>
              </div>

            </div>

            <div class="plan"> 
              <p class="plan__subtitle"><strong>The Growing</strong></p>
              <h2 class="plan__title title">Startup</h2>
              <ul class="plan__features"> 
                  <li class="gray-700">All <em>Side Hustle</em> features</li>
                  <li>In-depth insights</li>
                  <li>Client Relationship Management</li>
                  <li>Import bank statements</li>
                  <li>Invoice payments</li>
                  <li class="invisible"></li>
                  <li class="invisible"></li>
                  <li class="invisible"></li>
              </ul>
              <p class="plan__price subheading gray-700"><strong>R 279.00</strong> p/m</p>
              <div class="plan__cta">
                  <div class="pill">Coming soon</div>
              </div>
            </div>

            <div class="plan"> 
              <p class="plan__subtitle"><strong>The Established</strong></p>
              <h2 class="plan__title title">Entrepreneur</h2>
              <ul class="plan__features"> 
                  <li class="gray-700">All <em>Startup</em> features</li>
                  <li>Inventory management</li>
                  <li>SARS tax reconciliation</li>
                  <li class="invisible"></li>
                  <li class="invisible"></li>
                  <li class="invisible"></li>
                  <li class="invisible"></li>
                  <li class="invisible"></li>
                  <li class="invisible"></li>
              </ul>
              <p class="plan__price subheading gray-700"><strong>R 479.00</strong> p/m</p>
              <div class="plan__cta">
                  <div class="pill">Coming soon</div>
              </div>
            </div>
        </div>
      </section>

      <section class="section--not-sure">
        <div class="info-cards">
          <div class="info-card info-card--hero">
            <img src="../../assets/img/inventory.svg"/>
            <h2>Still not sure?</h2>
            <p class="subtext">stub is free for 30 days when you first <router-link to='/signup'>sign up</router-link>. You can cancel, upgrade or downgrade your subscription whenever you like.</p>
          </div>
        </div>
      </section>
    </div>

    <StaticFooter trackingTag="pricing"/>
  </div>
</template>

<script>
import StaticFooter from "../../components/StaticFooter.vue"

export default {
  name: 'pricing',
  components: {
    StaticFooter
  },
  data: function() {
    return {
      
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables';
.plans-header  {
  text-align: center;
  margin-bottom: $space-6;
}
.section--not-sure {
  margin-bottom: $space-6;
}
</style>
