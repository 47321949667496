<template>
    <section class="section--static-footer">
        <div class="page columns">
            <div class="one-of-four">
                <h5>stub</h5>
                <ul>
                    <li><router-link to="/">Features</router-link></li>
                    <li><router-link to="/pricing">Pricing</router-link></li>
                    <li><a href="https://medium.com/stubafrica">What's new?</a></li>
                </ul>
            </div>
            <div class="one-of-four">
                <h5>Resources</h5>
                <ul>
                    <li><a target="_blank" href="https://support.stub.africa">Help Centre</a></li>
                    <li><a href="https://medium.com/stubafrica">News</a></li>
                    <li><router-link to="/building-stub">Building stub</router-link></li>
                    <li>Media Centre <span class="pill pill--new">coming soon</span></li>
                    <li>Status <span class="pill pill--new">coming soon</span></li>
                    <li>API <span class="pill pill--new">coming soon</span></li>
                </ul>
            </div>
            <div class="one-of-four">
                <h5>Company</h5>
                <ul>
                    <li><router-link to="/about">About Us</router-link></li>
                    <li><router-link to="/about">Mission</router-link></li>
                    <li><router-link to="/careers">Careers</router-link></li>
                </ul>
            </div>
            <div class="one-of-four">
                <h5>Follow us</h5>
                <ul>
                    <li><a @click="instagram()" target="_blank" href="https://www.instagram.com/stubafrica/"><img class="" src="../assets/icons/instagram.svg" height="20px" width="20px"/>&nbsp;Instagram</a></li>
                    <li><a @click="facebook()" target="_blank" href="https://www.facebook.com/stubafrica/"><img class="" src="../assets/icons/facebook.svg" height="20px" width="20px"/>&nbsp;Facebook</a></li>
                    <li><a @click="twitter()" target="_blank" href="https://twitter.com/stubafrica"><img class="" src="../assets/icons/twitter.svg" height="20px" width="20px"/>&nbsp;Twitter</a></li>
                </ul>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'StaticFooter',
    props: {
        trackingTag: ''
    },
    methods: {
        facebook: function() {
            this.$store.dispatch('event','facebook-' + this.trackingTag);
        },
        twitter: function() {
            this.$store.dispatch('event','twitter-' + this.trackingTag);
        },
        instagram: function() {
            this.$store.dispatch('event','instagram-' + this.trackingTag);
        },
  }
}
</script>

<style lang="scss"scoped>
@import '../assets/scss/variables';

.section--static-footer {
    background: $gray-100;
    padding-top: $space-1;
    padding-bottom: $space-6;
    
    .columns {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;

        .one-of-four {
            width: 100%;
            padding-right: $space-1;
            box-sizing: border-box;

            h5 {
                margin: $space-5 0 $space-4;
            }

            ul {
                list-style: none;
                padding: 0;
                
                li {
                    margin: 0 0 $space-2 0;
                    // color: $gray-800;

                    a {
                        color: $gray-900;
                        text-decoration: none;
                        img {
                            margin-right: $space-1;
                        }

                        &:hover {
                            color: $dark;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (min-width: $breakpoint-sm) {
    .section--static-footer {
        .columns {
            .one-of-four {
                width: 50%;
            }
        }
    }
}
@media screen and (min-width: $breakpoint-md) {
    .section--static-footer {
        .columns {
            .one-of-four {
                width: 25%;
            }
        }
    }
}
</style>