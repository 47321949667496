<template>
  <div>
    <div class="page">
      <section>
        <div class="info-cards">
          <div class="info-card info-card--hero">
            <img src="../../assets/img/inventory.svg"/>
            <h1 class="super">We’re on a mission</h1>
            <h2>...to see your business succeed</h2>
            <p>Our aim is help small businesses succeed. Everyday. Every month. Year on year. Its as simple as that.</p>
          </div>

          <div class="info-card">
            <img src="../../assets/img/business.svg"/>
            <h2 class="title">We believe in small businesses</h2>
            <p>We know that Entrepreneurs and small businesses are the biggest source of economic activity for Africa. We also know how important it is that they succeed for the people of our country. stub makes it all a bit easier. We're here to fight the odds of small business failure!</p>
            <a href="https://medium.com/stubafrica" target="_blank" class="button button--secondary">Our story</a>
          </div>

          <div class="info-card">
            <img src="../../assets/img/hi-five.svg"/>
            <h2 class="title">Who we are</h2>
            <p>We're a very small team of entrepreneurs, designers and engineers based in sunny South Africa.</p>
            <router-link to="/careers" class="button button--secondary">Join us</router-link>
          </div>
        </div>
      </section>

      <section class="section--building-stub">
        <div class="info-cards">
          <div class="info-card info-card--hero">
            <h2>We're just getting started</h2>
            <p class="text">We're building a platform to change the way businesses manage their finances.</p>
            <router-link to="/building-stub" class="button button--secondary">See what's coming</router-link>
          </div>
        </div>
      </section>
    </div>

    <StaticFooter trackingTag="about" />
  </div>
</template>

<script>
import StaticFooter from "../../components/StaticFooter.vue"

export default {
  name: 'about',
  components: {
    StaticFooter
  },
  data: function() {
    return {
      
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables';

.info-cards {
  padding: $space-1 0;
}
.section--building-stub {
  margin-top: $space-4;
}

@media screen and (min-width: $breakpoint-md) {
  .section--building-stub {
    margin: $space-6 0;
  }
}
</style>
