import Vue from 'vue'
import Router from 'vue-router'

import Home from './views/static/Home.vue';
import About from './views/static/About.vue';
import Careers from './views/static/Careers.vue';
import Pricing from './views/static/Pricing.vue';
import BuildingStub from './views/static/BuildingStub.vue';
import Thankyou from './views/static/Thankyou.vue';
import PublicQuinvoice from './views/PublicQuinvoice.vue';

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};
Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/about',
      name: 'about',
      component: About,
    },
    {
      path: '/pricing',
      name: 'pricing',
      component: Pricing,
    },
    {
      path: '/careers',
      name: 'careers',
      component: Careers,
    },
    {
      path: '/building-stub',
      name: 'building-stub',
      component: BuildingStub,
    },
    {
      path: '/thankyou',
      name: 'thankyou',
      component: Thankyou,
    },
    {
      path: '/view/:uid/:id',
      name: 'public-quinvoice',
      component: PublicQuinvoice,
      meta: { 
        focusMode: true
      }
    },
    { 
      path: '*', 
      name: 'notfound',
      component: Home,
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})