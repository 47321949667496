<template>
  <div class="loading">
    <div class="loader"></div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    msg: String
  }
}
</script>

<style scoped lang="scss">
@import '../assets/scss/variables';
.loading {
  position: fixed;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $gray-200;
  z-index: 999999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

img {
  margin-bottom:  48px;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 30px;
  height: 30px;

}
.loader {
  margin: 0px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 2px solid transparent;
  border-right: 2px solid transparent;
  border-bottom: 2px solid transparent;
  border-left: 2px solid $primary;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

</style>
