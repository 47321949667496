<template>
<div>
  <div class="page">
    <section>
      <div class="info-cards">
        <div class="info-card info-card--hero">
          <img src="../../assets/img/build.svg"/>
          <h1 class="title">Join a world-class team taking on big opportunities</h1>
        </div>

        <div class="info-card">
          <h2>Help businesses succeed</h2>
          <p>We're building a platform that helps businesses succeed by making smart, informed financial choices. We're a small, passionate team of designers, engineers, data scientists, marketers and more. If that sounds like something you would like to be a part of, have a look at the job openings below and get in touch.</p>
        </div>

        <div class="info-card">
          <h2>Our culture is everything</h2>
          <p>We work hard. We play hard. We value diversity and different perspectives. We serve our customers. We make stub.</p>
          <a href="https://medium.com/stubafrica" target="_blank" class="button button--secondary">Our story</a>
        </div>
      </div>
    </section>

    <section class="section--job-openings">
      <h2 class="title">Current openings</h2>
      <p>Unfortunately we don't have any open positions at the moment.</p>
      <p class="subtext">If you still want to reach out and chat to someone in our team, send us a note on hi@stub.africa. We look forward to catching up.</p>
    </section>
  </div>

  <StaticFooter trackingTag="careers"/>
</div>
</template>

<script>
import StaticFooter from "../../components/StaticFooter.vue"

export default {
  name: 'careers',
  components: {
    StaticFooter
  },
  data: function() {
    return {
      
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables';
.section--job-openings {
  margin-bottom:$space-6;
}
</style>
