<template>
    <div class="list__row" @click="editPayment()">
        <div class="cell payment">
            <vue-autonumeric v-bind:value="value.amount"
                :options="{digitGroupSeparator: ' ', currencySymbol: currency, minimumValue: '0.00' }"
                tag="div" contenteditable="false"
                class="text"></vue-autonumeric>
            <div class="subtext"><em>{{formatDate(value.date)}}</em></div>
        </div>
        <div v-if="!locked" class="cell cell--chevron material-icons">
            chevron_right
        </div>

        <!-- Modal -->
        <modal v-bind:visible="isFocused" @close-event="closeModal()" heading="Payment">
            <div class="modal__content">
                
                <label for="amount">Amount</label>
                <vue-autonumeric v-model="value.amount"
                    :options="{digitGroupSeparator: ' ', currencySymbol: currency, minimumValue: '0.00'}"
                    :placeholder="currency+'0.00'"></vue-autonumeric>

                <label for="description">Payment date</label>
                <vuejs-datepicker name="payDate" v-model="value.date"></vuejs-datepicker>

                <label>Payment</label>
                <label class="radio-button">Account
                    <input type="radio" name="incomepayment" value="account" v-model="value.payment">
                    <span class="radio-mark"></span>
                </label>
                <label class="radio-button">Cash
                    <input type="radio" name="incomepayment" value="cash" v-model="value.payment">
                    <span class="radio-mark"></span>
                </label>
                
            </div>
            <div class="modal__buttons">
                <StubButton @click="updatePayment()" v-bind:isLoading="saving">Save</StubButton>
                <button class="button button--danger" @click="deletePayment()">Delete</button>
            </div>
        </modal>
        <!-- End Modal -->
        
    </div>
</template>

<script>
import Modal from './Modal.vue'
import StubButton from './StubButton.vue'
import VueAutonumeric from '../../node_modules/vue-autonumeric/src/components/VueAutonumeric.vue'
import VuejsDatepicker from 'vuejs-datepicker'

export default {
    name: 'InvoicePayment',
    components: {
        Modal,
        StubButton,
        VueAutonumeric,
        VuejsDatepicker
    },
    props: ['value','currency','locked'],
    data: function() {
        return {
            isFocused: false,
            isDeleted: false,
            saving: false
        }
    },
    methods: {
      editPayment() {
          if(!this.locked) this.isFocused = true;
      },
      updatePayment() {
          // TODO: Implement update event
          this.saving = true;
          this.$emit('update', this.value);
          var thisy = this;
          setTimeout(function() {
              thisy.isFocused = false;
              thisy.saving = false;
          },500)
      },
      deletePayment() {
          // TODO: Implement delete event
          this.$emit('delete', this.value);
          var thisy = this;
          this.isFocused = false;
      },
      closeModal: function() {
          this.isFocused = false;
      },
      formatDate: function(rawDate) {
        var date = new Date(rawDate);
        var monthNames = [
          "Jan", "Feb", "Mar",
          "Apr", "May", "Jun", "Jul",
          "Aug", "Sep", "Oct",
          "Nov", "Dec"
        ];

        var day = date.getDate();
        var monthIndex = date.getMonth();
        var year = date.getFullYear();

        return day + ' ' + monthNames[monthIndex] + ' ' + year; 
      }
    }
}
</script>

<style lang="scss" scoped>
@import './../assets/scss/variables';

.payment {
    .text {
        margin: $space-0 0;
    }
    .subtext {
        margin: $space-0 0;
    }
}
</style>