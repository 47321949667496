<template>
  <div class="copyright">
    <p class="subtext gray-700"> &copy; Stub Group 2020 All rights reserved.</p>
    <p class="subtext gray-700"><a @click="terms()" href="https://support.stub.africa/terms/" target="_blank">Terms</a> • <a @click="policy()" href="https://support.stub.africa/privacy/" target="_blank">Privacy</a> • <a @sitemap="refunds()" href="https://support.stub.africa/refunds/" target="_blank">Refunds</a></p>
  </div>
</template>

<script>
export default {
  name: 'Copyright',
  methods: {
    terms: function() {
      this.$store.dispatch('event','legal-terms');
    },
    policy: function() {
      this.$store.dispatch('event','legal-policy');
    },
    refunds: function() {
      this.$store.dispatch('event','legal-refunds');
    },
  }
}
</script>

<style scoped lang="scss">
@import './../assets/scss/variables';
.copyright {
  padding: $space-3 0;
  text-align: center;
}
.app--loggedin {
  .copyright {
    margin-top: $space-6;
  }
}
</style>
